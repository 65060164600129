var messageDom = require("./messageComponent");
var qrfDom = new q_DOM("q_messageBox");

var message = messageDom({
},qrfDom);
var html = `
<div><comp-${message.compName}></comp-${message.compName}></div>`;
qrfDom.render(html);
q_.registEvent("showMessageBox",function(name,param){
    console.log("enter showMessageBox");
    qrfDom.eves[message.compName].show(param);
});