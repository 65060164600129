//接着要添加的东西
//1. 可选择的样式，是否可以使用公用样式，也就是说，一次加载不用重复加载？不行，这样就缺少判极刑？如果想要这样使用，只能使用有限类处理，而不能使用无限样式设置，所以最好的解决方式是样式可以相互引用，但怎么设计呢？
//2. 点击前的处理函数
//3. 点击后的处理函数
//4. 防抖及loading
import {getDefaultColor,setStyle} from '../common/componentCommon';
var tempHtml = `
<div qrf-class="@(compName)@.show">
    <div class="comp-warning-title"><img src="./module/common/images/common/warning-icon.png" /></div>
    <div class="comp-warning-content" qrf-text="@(compName)@.content">
    </div>
    <div class="comp_warning_close" qrf-click="@(compName)@.close.bind(null)"><img src="./module/common/images/common/close-icon-white.png" /></div>
</div>
    `;
var definedIndex = 0;
function checkParam(param){
    return param;
}
function initStyle(compName,style){
    var defaultStyle = {
        ".comp_warning_info":{
            "position":"fixed",
            "top":"365px",
            "left":"calc(50% - 461px)",
            "width":"922px",
            "min-height":"350px",
            "border-radius":"24px",
            "background-color":"#000000",
            "z-index":"999",
            opacity: "0.7",
            ".comp-warning-title":{
                "position":"absolute",
                "top":"68px",
                "left":"416px",
                "width":"89px",
                "height":"89px",
                "img":{
                    "width":"100%",height:"100%",
                }
            },
            ".comp-warning-content":{
                "position":"absolute",
                "top":"188px",
                "left":"76px",
                "width":"770px",
                "min-height":"76px",
                "text-align":"center",
                "line-height": "38px",
                "font-size": "32px",
                "font-family": "Roboto-my",
                "font-weight": "bold",
                "color": "#FFFFFF",
            },
            ".comp_warning_close": {
                "position":"absolute",
                "top":"32px",
                "right":"32px",
                "width":"44px",
                "height":"44px",
                "img":{
                    "width":"100%","height":"100%","border-radius":"100%",
                }
            }
        },
        ".comp_warning_show":{
            "z-index":"101",
            "display":"block",
        },
        ".comp_warning_hidden":{
            "display":"none",
        },
    };
    // console.log(style);
    setStyle(style,defaultStyle['.comp_warning_info']);
    // console.log(defaultStyle);
    var s = defaultStyle;
    for(var key in s){
        q_style.writeStyle("qrfmessage_style",key+'[qrf-comp='+compName+']',s[key]);
    }
    q_style.apply("qrfmessage_style");
    return defaultStyle;
}
function initVar(keyName,param,vars){
    vars[keyName] = {
        show:"comp_warning_info comp_warning_hidden",
            content:"content（space）",
            title:"—warning—",
    };
}
function initEvents(keyName,param,eves,qrfDom){
    eves[keyName] = {
        show:function(param){
            var title = param.title;
            var content = param.content;
            qrfDom.set(keyName+".title",title);
            qrfDom.set(keyName+".content",content);
            qrfDom.set(keyName+".show","comp_warning_info comp_warning_show");
            q_.setEffCancel(qrfDom.eves[keyName].close,param.event,['click']);
        },
        close:function(e){
            console.log("enter message close");
            qrfDom.set(keyName+".show","comp_warning_info comp_warning_hidden");
        }
    };
}
function createDom(param,qrfDom){
    var compName = "qrfmessage_"+definedIndex;
    console.log(compName);
    checkParam(param);
    var style = initStyle(compName,param.style);
    initVar(compName,param,qrfDom.vars);
    initEvents(compName,param,qrfDom.eves,qrfDom);
    definedIndex ++;
    param.compName = compName;
    var domlltt = qrfDom.definedComp(compName,tempHtml,param);
    var dom = domlltt[compName].dom;
    return {
        compName,dom,style,
        vars:qrfDom.vars[compName],
        eves:qrfDom.eves[compName],
        setStyle:function(s){
            setStyle(s,this.style);
            // console.log(defaultStyle);
            var s = this.style;
            for(var key in s){
                q_style.writeStyle("qrfmessage_style",key+'[qrf-comp='+compName+']',s[key]);
            }
            q_style.apply("qrfmessage_style");
        }
    };
}
module.exports = createDom;