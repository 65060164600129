export function getDefaultStyle(x){
    switch(x){
        case "qrfblue":return "#1890ff";
        case "qrfred":return "#ff2050";
        case "qrfyellow":return "#ffaa40";
        case "qrfgreen":return "#13ce66";
        case "qrfgray":return "#e0e0e0";
    }
    return x;
};

export function setStyle(style,out){
    if(style == null || out == null)return;
    for(var key in style){
        if(typeof style[key] == 'string') out[key] = getDefaultStyle(style[key]);
        else if(typeof style[key] === 'object'){
            if(null == out[key])out[key] = {};
            setStyle(style[key],out[key]);
        }
    }
}

export function saveStyle(name,compName,style){
    var s = {};
    s['.'+name+'[qrf-comp='+compName+']'] = style;
    q_style.writeStyle(name+"_style",'.'+name+'[qrf-comp='+compName+']',s['.'+name+'[qrf-comp='+compName+']']);
    q_style.apply(name+"_style");
}

export function setClassStyle(style,out){
    if(style == null || out == null)return;
    for(var key in style){
        if(typeof style[key] == 'string') out[key] = getDefaultStyle(style[key]);
        else if(typeof style[key] === 'object'){
            if(null == out[key])out[key] = {};
            setStyle(style[key],out[key]);
        }
    }
}

export function saveClassStyle(tag,className,style){
    var s = {};
    s[tag+ '.'+className] = style;
    q_style.writeStyle(tag+"_"+className+"_style",tag+ '.'+className,s[tag+ '.'+className]);
    q_style.apply(tag+"_"+className+"_style");
}
export function checkSitdownTimeout(sitdownTime){
    let expire = 5*60;//两分钟
    let sitdownTimeout = false;
    if(sitdownTime){
        let t1 = new Date(sitdownTime);
        let t2 = new Date(q_U.getDateUTC());
        console.log(t1, t2);
        //计算相差时间
        t1 = t1 / 1000;
        t2 = t2 / 1000;
        console.log(t1, t2);
        if(t2 - t1 > expire){
            sitdownTimeout = true;
        }
    }
    else{
        sitdownTimeout = true;
    }
    return sitdownTimeout
}